.table-blk .rdt_Table {
  @apply text-lg text-font-dark !important;
}

.rdt_TableHeadRow, .answer-table thead {
  @apply text-left border-[1px] border-t-0 border-r-0 border-l-0 p-4  font-bold text-base border-b-cyan !important;
}
.rdt_TableCol {
  @apply h-5 w-5;
}

.rdt_TableRow, .answer-table tbody tr td div {
  @apply text-font-dark px-4 py-[11px] text-base border-b-cyan !important;
}

.rdt_TableCell {
  @apply pl-0;
}

.table-blk .rdt_TableRow button {
  @apply flex justify-center items-center p-0 text-sm font-bold border-secondarybtn-secondary border-[1px] mx-1 min-w-[75px] h-[35px];
}
.table-blk .secondary-btn {
  @apply max_md:min-w-[100px] min-w-[150px] max_xs:min-w-[150px];
}

.footer-table-btn {
  @apply flex justify-end py-10 sm:justify-center sm:py-[30px];
}

.footer-table-btn .success-btn {
  @apply w-[250px];
}

.rdt_Pagination {
  @apply text-font-dark !important;
}

.rdt_Pagination span:nth-child(1),
.gnaqej {
  display: none;
}

.rdt_Pagination span:nth-child(2) {
  @apply text-font-dark !important;
}

.rdt_Pagination button svg path:nth-child(1) {
  @apply fill-secondary;
}
.data_table_header {
  @apply w-full;
}
.table_btn_block {
  @apply flex justify-end gap-6 mb-5;
}
.rdt_TableHeadRow, .answer-table thead {
  @apply bg-background-table_header;
}

/* .table-space {
  @apply p-4 !important;
} */
