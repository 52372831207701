.btn {
  @apply rounded border-none font-semibold py-3 px-5 text-base;
}

.primary-btn {
  @apply bg-button-primary transition ease-linear duration-150 text-font-light drop-shadow-md min-w-[170px] rounded-[8px] shadow-[0px_6px_3px_-1px_rgba(0,0,0,0.16)] border-transparent border-4 border-solid;
}

.primary-btn:hover {
  @apply shadow-sm border-borderColor-300 border-4 border-solid;
}

.secondary-btn {
  @apply bg-secondarybtn-primary transition ease-linear duration-100 border-solid  border-[2px] border-secondarybtn-secondary  text-secondarybtn-secondary min-w-[170px] rounded-[50px] cursor-pointer shadow-[3px_3px_2px_-1px_rgba(0,0,0,0.16)] font-bold text-sm max_xs:min-w-[150px];
}

.secondary-btn.dark {
  @apply bg-secondarybtn-secondary text-secondarybtn-primary;
}

.light_green-btn {
  @apply bg-button-light_green text-secondarybtn-primary transition ease-linear duration-100 rounded-[50px] cursor-pointer shadow-[3px_3px_2px_-1px_rgba(0,0,0,0.16)] font-bold text-sm;
}

.danger-btn {
  @apply bg-button-danger text-secondarybtn-primary transition ease-linear duration-100 rounded-[50px] cursor-pointer shadow-[3px_3px_2px_-1px_rgba(0,0,0,0.16)] font-bold text-sm;
}

/* .secondary-btn-active {
    @apply opacity-100 bg-button-primary text-font-light
}

.secondary-btn:hover {
    @apply   opacity-100 bg-button-primary text-font-light shadow-sm
} */

/* .success-btn {
    @apply bg-button-success transition ease-linear duration-100 text-font-light drop-shadow-md min-w-[170px] rounded-[50px] shadow-[0px_6px_3px_-1px_rgba(0,0,0,0.16)];
} */

.success-btn:hover {
  @apply shadow-sm;
}

.multi-btn {
  @apply grid gap-4 grid-cols-[repeat(auto-fit,minmax(170px,max-content))] w-full justify-center;
}
