dialog {
  margin: auto;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 0em;
  place-content: center;
  background: transparent;
}

.backdrop {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1;
  backdrop-filter: blur(2px);
}

dialog {
  background-color: rgb(255, 255, 255);
}
dialog[open]:not(:focus-within) {
  background-color: rgb(255, 255, 254);
  transition: background-color 0.01s ease;
}

/*Popup card*/
.popup-wrapper {
  @apply fixed w-full  flex items-center justify-center top-0 left-0;
  z-index: 9999;
  min-height: 100%;
}
.popup-wrapper.hide {
  @apply hidden;
}

.popup-card {
  @apply relative mx-auto bg-white shadow-lg z-10;
  max-width: 700px;
  width: 90%;
}
@media (max-width: 576px) {
  .popup-card {
    text-align: left;
  }
}
.popup-card h2 {
  @apply text-lg font-bold uppercase font-Popp text-font-dark mb-4 max_xs:text-base;
}
.popup-card p {
  @apply text-base text-font-dark max_xs:text-sm;
}
.popup-card-content {
  @apply mx-auto;
  max-width: 1100px;
}
/* .popup-card-content::-webkit-scrollbar {
    display: none;
  } */
.popup-card-content .btn.outlined {
  @apply font-Popp text-white uppercase border-card-100 bg-font-dark w-full;
  max-width: 220px;
}
.popup-card-close {
  @apply absolute top-[1.5rem] right-[1.5rem];
}
.popup-card-circle {
  @apply flex items-center justify-center rounded-full cursor-pointer p-1.5;
}
.popup-scroll-hide {
  @apply overflow-hidden;
}
.popup-card.md {
  max-width: 600px;
  width: 90%;
}
.popup-card.lg {
  max-width: 1100px;
  width: 90%;
}

.popup-body {
  @apply px-4 py-8 overflow-y-auto my-4;
  max-height: calc(100vh - 190px);
  min-height: 220px;
}
@media (max-width: 800px) {
  .popup-body {
    max-height: calc(100vh - 190px);
  }
}

.popup-body.pd {
  @apply px-0 py-0 my-0;
}

.Ques_pop_head {
  @apply px-8 py-6 bg-primarynav-100 text-white;
}
.Ques_pop_head h3 {
  @apply text-xl text-white font-bold mb-[5px];
}
.popup-card .Ques_pop_head p {
  @apply text-white;
}
