.user-table-blk {
  @apply w-[90%] overflow-auto;
}

.user-table-blk .h1-text {
  @apply p-14 sm:py-[30px] flex justify-center text-lg sm:px-8;
}

.user-table-blk .table-blk table {
  @apply pl-24;
}

.table-filter-option {
  @apply w-full flex justify-end;
}

.time-info {
  @apply flex;
}

.time-info .fa {
  @apply pl-3;
}

.icon-style {
  @apply cursor-pointer;
}

.time-col-blk {
  @apply grid grid-cols-[85%_15%] items-center gap-[10px];
}

.no-data {
  @apply bg-tabBg p-6 w-full text-font-dark text-center font-semibold text-base rounded-xl;
}

.rdt_Table div {
  background-color: transparent;
}
