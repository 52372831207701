@tailwind base;
@tailwind components;
@tailwind utilities;

button:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px !important;
}

select:focus-visible {
  outline: -webkit-focus-ring-color auto 2px !important;
}
img:focus-visible,
img[tabindex='0']:focus-visible,
svg:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}

a:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}
.secondary-nav a:focus-visible {
  outline-offset: -4px;
  outline: -webkit-focus-ring-color auto 2px;
}
.role-block div[tabindex='0']:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
  outline-offset: -10px;
}
h1:focus-visible,
h2:focus-visible,
h3:focus-visible,
h4:focus-visible,
h5:focus-visible,
h6:focus-visible,
p:focus-visible,
label:focus-visible,
span:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}

input:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}

textarea:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}
input[type='checkbox'] + .checkmark:focus-visible,
input[type='radio'] + .checkmark:focus-visible,
input[type='checkbox']:focus-visible + .faq-toggle {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}
input[type='file']:focus-visible {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
  opacity: 1;
}

input:focus-visible + .checkmark,
input:focus-visible + .radio-checkmark,
input[type='checkbox']:focus-visible + span,
input[type='checkbox']:focus-visible + label,
input[type='radio']:focus-visible + span,
input[type='radio']:focus-visible + label {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}

.image-radio-group input[type='radio']:focus-visible ~ .add-image-block {
  outline-offset: 8px;
  outline: -webkit-focus-ring-color auto 2px;
}

*:focus {
  outline: transparent;
}
input[type='checkbox']:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
  outline-offset: 8px;
}
span[tabindex='0']:focus-visible,
div[tabindex='0']:focus-visible,
form[tabindex='0']:focus-visible,
div[tabindex='1']:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
  outline-offset: 8px;
}

button:focus-visible,
select:focus-visible {
  outline: #101010 auto 1px;
}

.react-tabs__tab-list div[tabindex='0']:focus-visible {
  outline: -webkit-focus-ring-color auto 2px;
  outline-offset: -5px;
}

/* Firefox */
body {
  @apply overflow-y-scroll h-screen;
  scrollbar-width: thin;
  scrollbar-color: #eee;
}

/* Chrome, Edge, and Safari */

body::-webkit-scrollbar,
.popup-body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track,
.popup-body::-webkit-scrollbar-track {
  width: 5px;
  background: #eee;
  border-radius: 1px;
}
.word_break{
  word-break: break-word;
}
input[type=checkbox]{
  accent-color: blue;
}