.react-select__option.react-select__option--is-focused {
    @apply bg-primarynav-100 text-white;
  }
  .react-select__option.react-select__option--is-selected
 {
    @apply bg-secondarynav-100 text-white;
  }

  .react-select__control.react-select__control--menu-is-open,
  .react-select__control{
    @apply border border-input shadow-none !important;
  }

  .react-select__menu-list{
    @apply max-h-[120px] overflow-y-auto !important;
  }