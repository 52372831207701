.footer_line {
  @apply w-full h-[5px] bg-background-line_color mb-11;
}
.footer_logo_nav_section {
  @apply ml-12 mb-[70px] max_md:grid max_md:gap-6 flex justify-between;
}
.footer_nav_section {
  @apply flex gap-8 mr-7 font-semibold justify-evenly w-full md:ml-[45px] ml-[50px] max_md:gap-5 max_md:justify-start max_md:ml-0 max_md:mr-12 max_md:grid;
}
.footer_block {
  @apply md:mb-14 max_xs:mb-0 max_s:mb-0;
}
.group_container {
  @apply flex justify-end mr-5 fixed bottom-5 right-0;
  box-shadow: 0px 2px 10px grey;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
}
.private_group_container {
  @apply flex;
}
