.input-group {
  @apply relative;
}

.input-group label,
.dropdown-label {
  @apply text-base block mb-2 font-medium text-left tracking-wide text-font-dark;
}

.input-group p {
  @apply text-base block mb-3;
}

.input-group .text-field:disabled::placeholder {
  @apply text-gray-400;
}

.input-group .text-field:disabled {
  @apply border-gray-200 text-opacity-70 cursor-not-allowed bg-gray-200;
}
.input-group .text-field {
  @apply relative border py-2 px-4 min-h-[48px] border-input  bg-transparent text-sm tracking-wide text-font-dark w-full mb-4 rounded-s;
  box-sizing: border-box;
  letter-spacing: 1px;
}

.input-group .text-field:focus {
  @apply outline-none border border-input;
}

.input-group .text-field::placeholder {
  @apply font-normal text-placeholder;
}

.form-error-msg {
  @apply mt-2 text-left text-sm text-error;
}

.input-group .text-field.input-error {
  @apply border border-error;
}

.input-group .sigCanvas {
  /* @apply relative border py-2 px-4 min-h-[200px] border-input  bg-white w-full mb-4 rounded-s; */
  @apply border mb-4 rounded-s;
}
.input-search,
.input-password {
  @apply flex absolute items-center h-full z-10;
  width: 40px;
}

.input-password {
  @apply -right-[3px]  bottom-[8px] cursor-pointer;
}

.input-password svg {
  fill: #00408a;
}

.info_icon {
  @apply cursor-pointer ml-2;
}

.psw_info_block {
  margin-bottom: 15px;
}

.psw_info_block span {
  display: block;
  font: italic normal normal 14px/21px Poppins;
  letter-spacing: 0px;
  color: #5d5d5d;
}

.input-group .error {
  @apply !border-error;
}
