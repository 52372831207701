@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import 'font-awesome/css/font-awesome.min.css';

.App {
  text-align: center;
}
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}
input[type='password']::-webkit-contacts-auto-fill-button,
input[type='password']::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
input::-webkit-caps-lock-indicator {
  content: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  @apply font-Popp;
}

#root {
  @apply font-Popp z-10 relative;
}

.main-section {
  @apply grid font-Popp;
  /* background: transparent linear-gradient(180deg, #51BDDDD9 0%, #94CC4CE6 100%) 0% 0% no-repeat padding-box; */
}

.main_card {
  @apply relative max-w-[760px] bg-primary m-auto w-[90%] py-[40px] px-10 rounded-[33px];
}

.sign-in {
  @apply px-[25px];
}

.sign-up {
  @apply max-h-24;
}

.find-org {
  @apply px-[25px] max_lg:pb-[40px];
}

.fm-block {
  @apply max-w-[385px] mx-auto;
}

.sign-up-otp {
  @apply px-[25px] max_md:py-[40px];
}

.fm-block .h1-text {
  @apply pb-4 max_md:text-xl text-2xl font-semibold;
  letter-spacing: 0px;
}

.invite-blk .h1-text {
  @apply py-2;
}

.invite-blk .note {
  @apply text-base;
}

.note span {
  @apply underline underline-offset-1 cursor-pointer;
}

.invite-blk .sub-text {
  @apply pt-1 pb-4 mb-0;
}

.verification-blk p {
  @apply py-2 mb-0;
}

.h1-text {
  @apply text-font-dark font-bold text-lg;
}

.footer-btn {
  @apply flex justify-center pt-4;
}

.footer-split-blk {
  @apply flex justify-center items-center text-font-dark mt-2;
}

.footer-split-blk :nth-child(1) {
  @apply mr-3 w-[40%] max-w-[155px] border-b-button-primary border-[1px] border-t-0 border-r-0 border-l-0;
}

.footer-split-blk :nth-child(2) {
  @apply ml-3 w-[40%] max-w-[155px] border-b-button-primary border-[1px] border-t-0 border-r-0 border-l-0;
}

.signup-btn-blk {
  @apply text-font-dark flex justify-center text-center;
}

.signup-btn-blk span {
  @apply underline text-font-dark font-bold cursor-pointer max_sm:block;
}

.error-message {
  @apply flex bg-bgError min-w-[100%] min-h-[115px] mb-5 mx-auto rounded-sm p-5 items-center text-center justify-center flex-col;
}
.error-message h2 {
  @apply text-error text-lg font-semibold;
}
.error-message p {
  @apply text-error;
}

.success-message {
  @apply flex bg-bgSuccess min-w-[100%] min-h-[115px] mb-5 mx-auto rounded-sm p-5 items-center text-center justify-center flex-col;
}

.success-message h2 {
  @apply text-success text-lg font-semibold;
}
.success-message p {
  @apply p-3 text-success;
}

.aw-error {
  @apply text-error -mt-2 mb-3;
}

.password-blk {
  @apply text-right text-font-dark;
}

.password-blk p {
  @apply inline-block underline cursor-pointer;
}

.fa {
  @apply text-font-dark text-xl cursor-pointer;
}

.eye-icon {
  @apply absolute right-0;
}

.forgot-password-blk {
  @apply px-[25px];
}

.forgot-password-blk .arrow {
  @apply absolute left-7 top-0 w-6 h-6 max_sm:left-[25px] cursor-pointer;
}

.forgot-password-blk .h1-text {
  @apply pb-6;
}

.success-btn:disabled,
.primary-btn:disabled,
.secondary-btn:disabled {
  @apply cursor-pointer  opacity-20;
}

.d-none {
  display: none;
}

.disable-transparent input:disabled {
  @apply bg-transparent w-full text-black !important;
  -webkit-text-fill-color: #000;
}

.textover {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.__react_component_tooltip {
  @apply w-[250px] whitespace-normal max_md:absolute max_md:left-0 max_md:-top-5 !important;
}

.__react_component_tooltip::after {
  @apply invisible !important;
}

.link {
  @apply pt-4 underline text-sm cursor-pointer;
}

.landing-page h1 {
  @apply lg:text-4xl md:text-3xl text-2xl font-Popp font-bold text-font-dark;
}

.landing_sub_head {
  @apply block text-3xl md:text-4xl lg:text-5xl  font-Popp font-bold text-font-dark mb-[40px] max_xs:text-sm;
}
.landing-page p {
  @apply text-font-dark font-normal text-base mb-5 leading-7 max_xs:text-sm;
}
.react-tabs__tab {
  @apply flex items-center justify-center w-[160px] border-0  text-lg !py-4;
  bottom: 0 !important;
  /* position: unset !important; */
}

.react-tabs__tab-list {
  @apply flex items-end justify-start m-0 max_md:text-[20px] text-[24px] text-center max_md:-mx-[20px] mb-5 !important;
  border-bottom: 2px solid #70707030 !important;
}
.react-tabs__tab-panel {
  @apply min-h-[100px] p-3;
}
.heading {
  @apply max_md:text-[24px] text-[28px] max_md:mb-[30px] mb-[12px] mt-14 font-semibold;
}
.auth-pera {
  @apply mb-10 text-base;
}

.react-tabs__tab.react-tabs__tab--selected {
  @apply !border-0 !border-solid bg-borderColor-200 text-white;
  border-color: #7c145f;
}
.heading_block {
  @apply text-lg mb-[15px] font-semibold mt-[35px];
}
.res_para_block {
  @apply text-base mb-6;
}
.video_player_block {
  @apply max_md:w-[90%] w-full m-auto;
}

.click_menu {
  @apply cursor-pointer whitespace-nowrap text-button-clickmenu font-semibold underline font-bold;
}
.more_info {
  @apply font-semibold mb-4 block;
}
.link-menu {
  @apply flex justify-end;
}
.link-menu span {
  @apply text-link underline text-base cursor-pointer font-medium;
  z-index: 1;
}

.datatabel-search-block {
  @apply grid sm:grid-cols-[repeat(auto-fit,minmax(250px,max-content))] w-full gap-7 my-5 sm:justify-between;
}

.rdt_Table {
  @apply border border-borderColor-400;
}

/* .rdt_TableHead,
.rdt_TableHeadRow {
  @apply rounded-t-sm;
} */
.rdt_TableBody,
.rdt_TableBody .rdt_TableRow:last-child, .answer-table tbody tr td {
  @apply rounded-b-sm;
}

.table-blk header {
  padding: 0;
}

.resent_link {
  @apply text-button-primary text-center mt-5;
}

.react-select__control {
  @apply min-h-[48px] !important;
}

.datatabel-search-block .input-group .text-field {
  @apply mb-0;
}
.rdt_TableRow:nth-child(odd), .answer-table tbody tr td div:nth-child(even) {
  @apply bg-background-primary;
}
.rdt_TableRow:nth-child(even), .answer-table tbody tr td div:nth-child(even) {
  @apply bg-background-table_even;
}
.rdt_TableHeadRow {
  @apply bg-background-table_header font-bold text-sm text-primary !important;
}
.back_arrow {
  @apply cursor-pointer;
}
.change_pass {
  @apply flex justify-between items-center mb-2;
}
.change_pass p {
  @apply mb-0 !important;
}

.tab_inactive:focus-visible {
  outline: transparent !important;
}

.quest_list svg path {
  fill: #000;
}

.selected_rec_block {
  @apply border-b mb-[70px];
}

.selected_rec_list {
  @apply grid grid-cols-4 gap-5 items-center h-[75px] px-5;
}
.selected_rec_list span:first-child {
  @apply font-bold;
}

.selected_rec_list:nth-child(odd) {
  @apply bg-background-table_even;
}

.forum_section_label {
  @apply flex justify-between items-center text-md block mb-7 mt-5 font-semibold text-left tracking-wide text-font-dark border-b border-borderColor-100 pb-2;
}
.forum_section_label .click_menu {
  @apply no-underline;
}
.back_section_header {
  @apply bg-background-table_header mx-[-50px] min-h-[80px] flex items-center text-font-light font-bold;
}
.back_block {
  @apply inline-flex text-font-light ml-[50px] items-center cursor-pointer;
}
.back_section_header svg path {
  fill: #fff;
}
.back_block p {
  @apply ml-3;
}
.hr_line {
  @apply flex justify-between items-center text-md block mb-7 font-semibold text-left tracking-wide text-font-dark border-b border-borderColor-100/30 pb-2;
}
.sub_heading {
  @apply text-[18px] font-bold mb-[15px];
}

.empty-block {
  @apply min-h-[200px] bg-background-card_color flex justify-center items-center max_xs:text-center;
}

.chatbot-list {
  @apply max-w-[750px] sm:w-[70%] border-2  border-borderColor-100 px-6 py-4 mt-[35px] mx-[45px] rounded-[5px];
}
.chatbot-block:last-child .chatbot-list {
  @apply mb-[35px];
}
.chatbot-list h2 {
  @apply text-base font-semibold;
}
.chatbot-block.right-chat-block {
  @apply flex justify-end;
}

.chatbot-response-block {
  @apply bg-background-card_color border-t border-borderColor-600 relative;
}
.chatbot-response-list {
  @apply mx-[45px] py-5;
}
.chatbot-response-list h2 {
  @apply font-semibold text-base mb-[10px];
}
.chat-close {
  @apply absolute right-5 top-5 cursor-pointer;
}

.chat-close svg path {
  fill: #707070;
}
.chat_block {
  @apply bg-background-card_color py-[35px] px-[45px] border-t border-borderColor-600;
}
.chat_text-block {
  @apply flex justify-between items-end flex-wrap md:flex-nowrap;
}
.chat_text-block textarea {
  @apply max-w-[750px];
}
.chat_bot_card {
  @apply flex justify-between flex-wrap font-medium mt-[10px];
}
.chat_bot_card span {
  @apply text-error;
}
.chat_bot_moderator {
  @apply flex justify-end;
}

.mini-card-block {
  @apply grid md:grid-cols-2 gap-5;
}

.user-list-block {
  @apply flex;
}
.user-list-info {
  @apply relative border rounded-md pl-5 pr-7 py-2 mr-3 mb-3 text-sm text-font-dark;
}
.user-list-info svg {
  @apply w-[12px] h-[12px];
}
.user-list-info svg path {
  @apply fill-gray-500;
}

.user-list-close {
  @apply absolute right-2 top-3 cursor-pointer;
}
.splide__arrow--prev {
  @apply left-[-37px] !important;
}
.splide__arrow--next {
  @apply right-[-37px] !important;
}
.card_align {
  @apply pb-[45px];
}
.submit_block {
  @apply mb-10;
}
/* text-right mb-10 */
.submit_block p {
  @apply text-[18px] font-medium mb-[30px];
}
.accordion_space_block {
  @apply py-5;
}

.responses-view-block {
  @apply my-4 mx-auto;
  max-width: 1000px;
}
.view-data-group {
  @apply mb-4;
}
.view-data-group .input-group {
  @apply mt-5;
}

.view-data-group > label:first-child,
.view-data-group .input-group label,
.view-data-group .dropdown-label {
  word-wrap: break-word;
  max-width: 95%;
  @apply text-md mb-4 inline-block font-normal;
}

/* .view-data-group label {
  @apply !inline-flex flex-wrap;
} */

.view-data-group p {
  @apply text-base font-normal;
}
.range-block {
  @apply flex items-center h-5;
}
.range-slider-info {
  @apply flex justify-between mt-2;
}
.statment-block {
  @apply break-words text-lg font-bold italic text-font-dark leading-5;
}
.view-data-group p {
  @apply text-base leading-6 text-font-dark break-words mb-[14px];
}
.op_statement {
  /* @apply border-width border-borderColor-200 h-[3px] w-full; */
  border-bottom: 3px solid #7c145f;
  padding-bottom: 20px;
}
.close_statement {
  border-top: 3px solid #7c145f;
  padding-top: 20px;
}
.fyrdjl:hover:not(:disabled) {
  background-color: transparent !important;
}

.range-block {
  @apply flex items-center h-5;
}
.range-slider-info {
  @apply flex justify-between mt-2;
}
.range-label-wrapper {
  @apply flex items-baseline justify-between;
}
.range-label-wrapper span {
  @apply mt-2 relative w-0 flex justify-center text-sm md:text-base;
}
.range-label-wrapper span::before {
  content: '';
  width: 2px;
  height: 7px;
  position: absolute;
  top: -7px;
  background-color: #bebebe;
}
.range-label-md-block {
  @apply grid gap-4 grid-cols-[repeat(auto-fit,minmax(150px,max-content))] w-full bg-card-100 p-5 mt-5;
}

.range-label-md-block span {
  @apply block text-sm;
}

.range-label-md-block span:last-child {
  @apply mb-0;
}

.consent_fm > label:first-child,
/* .consent_fm .input-group label, */
.consent_fm .dropdown-label {
  /* @apply text-[15px] font-bold mb-3 inline-block; */
  @apply text-sm font-medium  mb-[20px] inline-block;
}

.consent_fm .input-group label {
  @apply mb-3;
}
.consent_fm p {
  @apply text-base font-normal;
}

.consent_fm p {
  @apply text-base leading-6 text-font-dark break-words mb-[14px];
}
.consent_fm_heading {
  @apply text-lg font-medium mt-[30px] mb-[30px];
}

.consent_fm .input-group {
  @apply mt-0;
}

/* .consent_fm_label {
  @apply text-sm font-medium  mb-[20px];
} */
.download_block {
  @apply flex mb-5 mt-5;
}
.print_block {
  @apply ml-5;
}

.print-btn {
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 84px !important;
  margin-left: 25px;
}

.print-btn img {
  width: 35px;
}
span.search-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0, -50%);
}
.forum_overview span.search-icon {
  top: 30%;
  transform: translate(0, -30%);
}
.user_block select {
  width: fit-content;
}
.table-blk .rdt_TableRow button.click_menu {
  height: unset;
  min-height: unset;
  min-width: unset;
}

#dots #dot1 {
  animation: load 1s infinite;
}

#dots #dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#dots #dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.rate-definition p {
  font-size: 16px !important;
}
.triangle_img_float img {
  @apply float-right;
}
.land_img_block {
  @apply flex max_md:flex-col max_md:items-center;
}
.land_img_block img {
  @apply w-80 mb-5;
}
.react-tabs__tab-panel {
  @apply !p-0;
}
.accordion__button {
  @apply text-font-dark rounded-[5px] pl-5 pt-4 pb-4 pr-10 text-base font-bold relative bg-background-accordion_color;
  opacity: 1;
  border-bottom: 1px solid #fff;
}
.accordion__button[aria-expanded='false']::after {
  content: '';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
  height: 0;
  width: 0;
  position: absolute;
  right: 20px;
}
.accordion__button[aria-expanded='true']::after {
  content: '';
  border-bottom: 10px solid #000;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 20px;
}
.accordion__panel {
  @apply bg-background-accordion_color pr-5 pl-5 rounded-b-[5px];
}
.accordion__item {
  @apply mb-5;
}

.form-group-date .react-datepicker-wrapper {
  @apply !w-full;
}


  .react-datepicker__year-option:first-child .react-datepicker__navigation::before {
  content: "";
  position: absolute;
  top: 40%;
  left: 25%;
  margin: auto;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  
  border-bottom: 10px solid black;
}


  .react-datepicker__year-option:last-child .react-datepicker__navigation::after {
  content: "";
  display: block;
  position: relative;
  left: 15%;
  bottom: -5px;
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  
  border-top: 10px solid black;
}

.react-datepicker__header__dropdown{
  display: flex !important;
  justify-content: center !important;
  gap:20px !important;
}
.react-datepicker__header__dropdown .react-datepicker__month-read-view--down-arrow, .react-datepicker__header__dropdown .react-datepicker__year-read-view--down-arrow{
  right: -30px !important;
  top: 5px !important;
}