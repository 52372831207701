.primary-nav {
  @apply bg-primarynav-100;
}
.primary-nav .nav-list {
  @apply flex items-center max_md:justify-start justify-evenly w-full;
  min-height: 55px;
}
.primary-nav .nav-item {
  @apply relative m-0 max_xs:mr-2;
}

.primary-nav .nav-list a {
  @apply text-primarynav-200 font-Popp text-base max_xs:text-sm;
}
.header_nav_fix {
  @apply relative;
}
.primary-nav .nav-list a.active {
  @apply text-primarynav-200 font-Popp text-base font-bold max_xs:text-sm;
}
.primary-nav .nav-list a.active::after {
  content: '';
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 10px solid;
  border-top: 0;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -15px;
  margin-left: auto;
  margin-right: auto;
  height: 0px;
  width: 0px;
}
.secondary-nav {
  @apply bg-secondarynav-100;
}
.secondary-nav .nav-list {
  @apply relative flex items-center justify-evenly w-full overflow-x-scroll;
  min-height: 55px;
}
.secondary-nav .nav-item {
  @apply text-secondarynav-200 font-Popp max_sm:text-sm text-base text-center p-2;
}

.secondary-nav .active {
  @apply relative font-bold text-secondarynav-200;
}
.secondary-nav .active:after {
  @apply absolute left-0 right-0 max_sm:-bottom-[10px] -bottom-2 ml-auto mr-auto h-0 w-0;
  content: '';
  border-left: 10px solid transparent !important;
  border-right: 10px solid transparent !important;
  border-bottom: 10px solid;
  border-top: 0;
}

.third-nav {
  @apply border;
}
.third-nav .nav-list {
  @apply relative flex items-center justify-evenly w-full overflow-x-scroll;
  min-height: 55px;
}
.third-nav .nav-item {
  @apply text-secondarynav-100 font-Popp max_sm:text-sm text-base text-center p-2;
}

.third-nav .active {
  @apply relative font-bold text-secondarynav-100;
}

.nav-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.sub_menu_list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-height: 50px;
  border-bottom: 1px solid;
  /* position: fixed;
  width: 100%;
  top: 105px;
  z-index: 100; */
  background-color: #fff;
}
.sub_menu_list .active {
  @apply font-bold;
}

.sticky-nav {
  @apply fixed top-0 w-full z-50;
}

.sticky-sub-nav {
  @apply fixed top-[110px] z-50 w-full;
}
