.header-logo {
  @apply max-w-[320px] w-full  border-cyan max_lg:border-[0px]
   max_lg:max-w-[inherit] flex justify-between p-2 items-center;
}

.header-logo span {
  @apply hidden cursor-pointer;
}

.primary-header {
  @apply flex h-24 bg-background-primary items-center justify-between border-b-[5px];
}

.primary-header img {
  @apply max_md:ml-[20px] ml-[40px] my-[18px] mr-[30px] w-[160px] max_lg:mt-[25px] max_sm:mr-[10px] cursor-pointer;
}

.header-logo img {
  @apply max_lg:mt-[18px];
}

.hidden {
  @apply mb-[10px];
}

.secondary-header img {
  @apply m-0 -mt-[1px] w-[35px] h-[35px] cursor-pointer;
}

.active-profile {
  @apply bg-lime-500 w-[37px] h-[37px] rounded-full;
}

.inactive-profile {
  @apply bg-transparent w-[37px] h-[37px] rounded-full;
}

.primary-header h1 {
  @apply flex justify-center;
}

.secondary-header {
  @apply relative flex w-full justify-between min-h-[96px]  border-cyan border-b-[2px]  items-center  z-10;
}

.secondary-header .h1-text {
  @apply max_md:text-base max_xl:w-fit px-4;
}

.account-blk {
  @apply grid grid-cols-[155px_40px] sm:grid-cols-[135px_40px] justify-end  mr-[40px] max_sm:mr-[20px];
}

.account-blk div p:nth-child(1) {
  @apply text-font-dark font-bold text-sm mr-[6px] -mb-[2px]  text-end;
}

.account-blk div p:nth-child(2) {
  @apply text-font-dark text-sm mr-[6px] text-end;
}

.account-blk p {
  @apply font-Popp text-end;
}

.menu-dropdown {
  @apply font-bold;
}

.menu-dropdown::after {
  @apply absolute  right-[48px] bottom-[15px] max_lg:right-[35px] max_sm:right-[0px] ml-auto mr-auto h-0 w-0 border-[10px] border-t-0 border-l-primary border-r-primary;
  content: '';
}

.sub-menu-dropdown {
  @apply absolute h-[30px] w-[180px] top-[79px]  right-[14px]  text-font-dark hover:bg-cyan cursor-pointer;
}

.sub-menu-dropdown div {
  @apply text-font-dark border-[1px] border-cyan  hover:bg-tabBg hover:text-font-dark cursor-pointer text-sm bg-primary py-[8px] pl-[10px] shadow-[0px_4px_5px_-1px_rgba(0,0,0,0.16)];
}
